import { Component } from '@angular/core';

import { Platform, ModalController } from '@ionic/angular';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from './app-config';
import { ContactPage } from './pages/contact/contact.page';
import { ProfilePage } from './pages/profile/profile.page';
import { LanguagePage } from './pages/language/language.page';

import { LoginPage } from './pages/login/login.page';

import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
//import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { UserService } from './providers/user.service';
import { WoocommerceService } from './providers/woocommerce.service';
//import OneSignal from 'onesignal-cordova-plugin';
import { MenuController } from '@ionic/angular';
import { SowService } from './providers/sow.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  billingAddress = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    country: '',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    postcode: '',
    geocode:''
  };
  userInfo = {
    id: '',
    username: '',
    email: '',
    first_name: '',
    last_name: '',
    sow_vendor_geocoding: '',
    sow_vendor_address: '',
    phone: '',
    address_1: '',
    city: '',
    postalcode: '', 
    isAuthenticated: '',
    role: '',
    onesignalID: '',
    onesignalToken: ''
  };
  myLat: any;
  myLng: any;
  sow_vendor_geocoding: '';
  sow_vendor_address: ''; 
  myAddress: any;
  
  Shop_title: string;
  cartTabBage: number = 0;
  Shop_Version: string;
  myaddress: any; 

  public appPages = [
    { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(
    private platform: Platform,
    private translate: TranslateService,
    public appConfig: AppConfig,
    public modalCtrl: ModalController,
    public router: Router,
    //public nativeStorage: NativeStorage,
    public storage: Storage,
    public userService: UserService,
    public wooService: WoocommerceService,
    private menuCtrl: MenuController,
    public sowService: SowService
  ) {
    this.initializeApp();
    this.translate.setDefaultLang(this.appConfig.Shop_Language);
    this.translate.use(this.appConfig.Shop_Language);
    this.Shop_Version = this.appConfig.Shop_Version;
    this.Shop_title = this.appConfig.Shop_Name;
  }

  ngOnInit() {
    this.storage.create();
    this.storage.get('oddwolves-user-language').then((data) => {
      if (data) {
        this.translate.setDefaultLang(data);
        this.translate.use(data);
      } else {
        this.translate.setDefaultLang(this.appConfig.Shop_Language);
        this.translate.use(this.appConfig.Shop_Language);
      }
    }); 
  }
 
  initializeApp() {
    this.platform.ready().then(() => {

      this.wooService.getStoreInfo().then((storeInfo: any) => {
        this.appConfig.Shop_Name = storeInfo.store.name;
        this.appConfig.Shop_Currency = storeInfo.store.meta.currency;
        this.appConfig.Shop_Currency_format = storeInfo.store.meta.currency_format;
      });

      this.storage.get('pushNotificationStatus').then((data) => {
        if (data) {
          this.userService.pushNotifications = data;
        } 
      });


      this.storage.get('oddwolves-user-info').then((data) => {
        if (data) {
          //console.log(data);
          //this.billingAddress = JSON.parse(data);
          //this.userInfo = JSON.parse(data);
          var userInfo = JSON.parse(data);
          this.userService.id = userInfo.id;
          this.userService.email = userInfo.email;
          this.userService.first_name = userInfo.first_name;
          this.userService.last_name = userInfo.last_name;
          this.userService.name = userInfo.first_name + ' ' + userInfo.last_name;
          this.userService.username = userInfo.username;
          this.userService.role = userInfo.role;
          this.userService.cards = userInfo.cards;
          this.userService.isAuthenticated = true;
          this.userService.name = userInfo.firstname + ' ' + userInfo.lastname;
          this.userService.sow_vendor_geocoding = userInfo.sow_vendor_geocoding;
          this.userService.sow_vendor_address = userInfo.sow_vendor_address;
          this.userService.phone = userInfo.phone;
          this.userService.address_1 = userInfo.address_1;
          this.userService.city = userInfo.city;
          this.userService.onesignalID = this.userInfo.onesignalID;
          this.userService.onesignalToken = this.userInfo.onesignalToken;
          this.userService.postalcode = userInfo.postcode;
        } else {
          this.userService.onesignalID = this.userInfo.onesignalID;
          this.userService.onesignalToken = this.userInfo.onesignalToken;
        }
        //console.log(this.userService);
      });

      this.sowService.getAppData().then(async(data) => {
        console.log(data['data']);
        let appData = data;
    });


    });
  }


  vendorLogin() {
    this.menuCtrl.close();
    this.modalCtrl.create({ component: LoginPage }).then((value) => {
      value.present();
    });
  }

  goProfile() {
    this.menuCtrl.close();
    this.router.navigate(['profile']);
  }

  openVendorOrderList() {
    this.menuCtrl.close();
    this.router.navigate(['manage-orders-list']);
  }

  openVendorBookingList() {
    this.menuCtrl.close();
    this.router.navigate(['vendor-booking-list']);
  }

  modalContact() {
    this.menuCtrl.close();
    /*this.modalCtrl.create({ component: ContactPage }).then((value) => {
      value.present();
    });*/
    this.router.navigate(['contact']);
  }

  modalAbout() {
    this.menuCtrl.close();
    /*this.modalCtrl.create({ component: ContactPage }).then((value) => {
      value.present();
    });*/
    this.router.navigate(['about']);
  }

  modalLanguage() {
    this.menuCtrl.close();
    /*this.modalCtrl.create({ component: LanguagePage }).then((value) => {
      value.present();
    });*/
    this.router.navigate(['language']);
  }
}
