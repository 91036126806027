import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import { NavController, LoadingController, ModalController, AlertController } from '@ionic/angular';
import { TbarService } from '../../providers/tbar.service';
import { UserService } from '../../providers/user.service';
import { SowService } from '../../providers/sow.service';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AppConfig } from './../../app-config';

@Component({
  selector: 'app-password',
  templateUrl: './password.page.html',
  styleUrls: ['./password.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PasswordPage implements OnInit {
  lang_id: string;
  email: string;
  errorModal: any;

  constructor(public sowService: SowService, public alertCtrl: AlertController, public appConfig: AppConfig, public navCtrl: NavController, public loadingCtrl: LoadingController, public userService: UserService
    , public viewCtrl: ModalController, public tbarService: TbarService, public storage: Storage, public translateService: TranslateService,
    public router:Router) { 
 
     }

  ngOnInit() {
  }

  close() {
    this.viewCtrl.dismiss();
  }

  back(){
    this.navCtrl.back();
  }

  doReset() {
    this.translateService.get(['Notice', 'Loading', 'NetWork_Error', 'OK', 'Reset_PW_Failed', 'Reset_PW_OK', 'No_Email']).subscribe(async value => {
      const loadingModal = await this.loadingCtrl.create({
        spinner: null,
        cssClass: 'alertBg',
        message: '<div class="circle-loader load-complete">'+
                '<div class="checkmark draw disp"></div>'+
                '</div><div></div>'
    });
      if (this.appConfig.Eanble_Login_With_Password) {
        if (this.email == null || this.email.trim().length == 0) {
          const alert = await this.alertCtrl.create({
            header: value['Notice'],
            message: value['No_Email'],
            buttons: [value['OK']]
          });
          await alert.present();
          return;
        }

        loadingModal.present();

        this.sowService.resetPassword({ email: this.email, lang: this.lang_id }).then(async (data: any) => {
        console.log(data);
          if (data == true) {
            loadingModal.dismiss();
          const alert = await this.alertCtrl.create({
          header: value['Notice'],
          message: value['Reset_PW_OK'],
          buttons: [{
            text: 'OK', handler: () => {
              this.email = '';
              this.viewCtrl.dismiss();
            }
          }]
        })
        await alert.present();
          } 
          else {
            loadingModal.dismiss();
            const alert = await this.alertCtrl.create({
              header: value['Notice'],
              message: value['Reset_PW_Failed'],
              buttons: [value['OK']]
            })
            await alert.present();
          }
        }, (reson) => {

          loadingModal.dismiss();
        });
      }
    });

  }

}

