import { Injectable } from '@angular/core';
// import 'rxjs/add/operator/map';
//import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Storage } from '@ionic/storage';
import { AppConfig } from '../app-config';

/*
  Generated class for the UserService provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/
@Injectable({
  providedIn: 'root',
})
export class UserService {
  LOCAL_TOKEN_KEY = 'Mangiamoo';
  id = '';
  username = '';
  email = '';
  name = '';
  first_name = '';
  last_name = '';
  sow_vendor_geocoding = '';
  sow_vendor_address = '';
  phone = '';
  address_1 = '';
  city = '';
  cards = new Array<any>();
  role=new Array<any>();
  postalcode = '';
  isAuthenticated = false;
  authToken = '';
  cachedData: any;
  onesignalID: any;
  onesignalToken: any;
  pushNotifications: true;
  avatar_url='assets/img/sowuser_place_holder.png';
  constructor(/*public nativeStorage: NativeStorage,*/public storage: Storage, public appConfig: AppConfig) {
    this.LOCAL_TOKEN_KEY = appConfig.Shop_Name;
  }

  loadUserCredentials() {
    this.storage.get(this.LOCAL_TOKEN_KEY).then(user => {
      if (user) {
        this.useCredentials(JSON.parse(user));
      }
    });
  }

  useCredentials(user) {
    this.id = user.id;
    this.username = user.username;
    this.email = user.email;
    this.name = user.firstname + ' ' + user.lastname;
    this.first_name = user.firstname ;
    this.last_name = user.lastname;
    this.sow_vendor_geocoding = user.sow_vendor_geocoding;
    this.sow_vendor_address = user.sow_vendor_address;
    this.phone = user.phone;
    this.address_1 = user.address_1;
    this.city = user.city;
    this.postalcode = user.postalcode;
    this.isAuthenticated = true;
    this.cards=user.cards;
    this.role=user.role;
    this.avatar_url=user.avatar;
    this.onesignalID = user.onesignalID;
    this.onesignalToken = user.onsesignalToken;
    this.pushNotifications = user.pushNotifications;
    this.authToken = JSON.stringify(user);
  }

  destroyUserCredentials() {
    this.authToken = undefined;
    this.id = '';
    this.username = '';
    this.email = '';
    this.name = '';
    this.first_name = '';
    this.last_name = '';
    this.sow_vendor_geocoding = '';
    this.sow_vendor_address = '';
    this.phone = '';
    this.address_1 = '';
    this.city = '';
    this.postalcode = '';
    this.avatar_url='assets/img/sowuser_place_holder.png';
    this.role=new Array<any>();
    this.cards = new Array<any>();
    this.onesignalID = '';
    this.onesignalToken = '';
    this.isAuthenticated = false;
    this.pushNotifications = true;
    this.storage.remove(this.LOCAL_TOKEN_KEY);
  }
}
