import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavController, ModalController, AlertController, LoadingController } from '@ionic/angular';
import { UserService } from '../../providers/user.service';
import { WoocommerceService } from '../../providers/woocommerce.service';
import { SowService } from '../../providers/sow.service';
import { AppConfig } from '../../app-config';
import { Storage } from '@ionic/storage';
import * as CryptoJS from 'crypto-js';
import { TranslateService } from '@ngx-translate/core';
import { PasswordPage } from '../password/password.page';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginPage implements OnInit {
  userName: string;
  password: string;
  email: string;
  errorModal: any;
  constructor(public navCtrl: NavController, public userService: UserService, public wooService: WoocommerceService,
    public appConfig: AppConfig, public alertCtrl: AlertController, public loadingCtrl: LoadingController, public sowService: SowService, public storage: Storage
    , public translateService: TranslateService, public modalCtrl: ModalController) {
      console.log('OneSignal Info:'+this.appConfig.OneSignal_UserId, this.appConfig.OneSignal_PushToken);
  }

  ngOnInit(){
    
  }


  doPassword() {
    this.doClose();
    this.modalCtrl.create({ component: PasswordPage }).then((value) => {
      value.present();
    });
    //this.navCtrl.navigateForward('register');
  }

  /*doLogin() {
    this.translateService.get(['Notice', 'Loading', 'NetWork_Error', 'OK', 'Sign_In_Failed', 'Sign_In_No_Email', 'Sign_In_Email_Error', 'No_Email_Or_Password']).
      subscribe(async value => {
        var loadingModal = await this.loadingCtrl.create({
          message: value['Loading']
        });
        if (this.appConfig.Eanble_Login_With_Password) {
          if (this.email == null || this.password == null || this.email.trim().length == 0 || this.password.trim().length < 6) {
            var alert = await this.alertCtrl.create({
              header: value['Notice'],
              message: value['No_Email_Or_Password'],
              buttons: [value['OK']]
            });
            alert.present();
            return;
          }

          loadingModal.present();

          var password = this.password;
          if (this.appConfig.App_Secret != '') {
            var key = CryptoJS.enc.Utf8.parse(CryptoJS.MD5(this.appConfig.App_Secret).toString());
            var iv = CryptoJS.enc.Utf8.parse(CryptoJS.MD5(this.appConfig.App_Secret).toString().substr(0, 8));
            password =CryptoJS.DES.encrypt(password, key, { iv: iv }).toString();
          }


          this.sowService.login({ email: this.email, password: encodeURIComponent(password) }).then(async (data: any) => {
            if (data && data != 'error') {
              this.userService.id = data.id;
              this.userService.email = data.email;
              this.userService.first_name = data.first_name;
              this.userService.last_name = data.last_name;
              this.userService.name = data.first_name + data.last_name;
              this.userService.username = data.username;
              this.userService.isAuthenticated = true;

              await this.storage.set('oddwolves-user-info', data);
              loadingModal.dismiss();
              this.modalCtrl.dismiss();
            } else {
              loadingModal.dismiss();
              var alert = await this.alertCtrl.create({
                header: value['Notice'],
                message: value['Sign_In_Failed'],
                buttons: [value['OK']]
              });
              alert.present();

            }
          }, (reson) => {

            loadingModal.dismiss();
          });
        }
        else {
          if (this.email == null || this.email.trim().length == 0) {
            var alert = await this.alertCtrl.create({
              header: value['Notice'],
              message: value['Sign_In_No_Email'],
              buttons: [value['OK']]
            });
            alert.present();
            return;
          }
          loadingModal.present();
          this.wooService.getCustomerByEmail({ email: this.email }).then(async (data: Array<any>) => {
            if (data.length > 0 && data[0] && data[0].id > 0) {
              this.userService.id = data[0].id;
              this.userService.email = data[0].email;
              this.userService.first_name = data[0].first_name;
              this.userService.last_name = data[0].last_name;
              this.userService.name = data[0].first_name + data[0].last_name;
              this.userService.username = data[0].username;
              this.userService.isAuthenticated = true;
              loadingModal.dismiss();
              this.modalCtrl.dismiss();
            } else {
              loadingModal.dismiss();
              var alert = await this.alertCtrl.create({
                header: value['Notice'],
                message: value['Sign_In_Email_Error'],
                buttons: [value['OK']]
              });
              alert.present();
            }
          }, (reson) => {
            loadingModal.dismiss();
          });
        }
      });

  }*/

  doLogin() {
    console.log("start login");
      this.translateService.get(['Notice', 'Loading', 'NetWork_Error', 'OK', 'Sign_In_Failed', 'Sign_In_No_Email', 'Sign_In_Email_Error', 'No_Email_Or_Password']).subscribe(async value => {
        const loadingModal = await this.loadingCtrl.create({
          spinner:null,
          cssClass: 'alertBg',
          message: `
                <div class="custom-spinner-container">
                  <div class="custom-spinner-box">
                     <img src="assets/img/pizza_spinner.gif" /><br>
                     <span>`+value['Loading']+`</span>
                  </div>
                </div>`
              }); 
        if (this.appConfig.Eanble_Login_With_Password) {
          if (this.email == null || this.password == null || this.email.trim().length == 0 || this.password.trim().length < 6) {
            var alert = await this.alertCtrl.create({
              header: value['Notice'],
              message: value['NetWork_Error'],
              buttons: [value['OK']]
            });
            await alert.present();
            return;
          } 
  
          loadingModal.present();
  
          var password = this.password;
          if (this.appConfig.App_Secret != '') {
            var key = CryptoJS.enc.Utf8.parse(CryptoJS.MD5(this.appConfig.App_Secret).toString());
            var iv = CryptoJS.enc.Utf8.parse(CryptoJS.MD5(this.appConfig.App_Secret).toString().substr(0, 8));
            password = CryptoJS.DES.encrypt(password, key, { iv: iv }).toString();
          } 
            //console.log("start api");
          this.sowService.login({ email: this.email, password: encodeURIComponent(password), onesignal_id: this.appConfig.OneSignal_UserId }).then(async (data: any) => {
            if (data && data.id && data.id > 0) {
            //console.log(data);
              this.userService.id = data.id;
              this.userService.email = data.email;
              this.userService.first_name = data.first_name;
              this.userService.last_name = data.last_name;
              this.userService.name = data.first_name + data.last_name;
              this.userService.username = data.username;
              this.userService.sow_vendor_geocoding = data.sow_vendor_geocoding;
              this.userService.sow_vendor_address = data.sow_vendor_address;
              
              this.userService.phone = data.phone;
              this.userService.address_1 = data.address_1;
              this.userService.postalcode = data.postcode;
              this.userService.city = data.city;
              this.userService.onesignalID = this.userService.onesignalID;
              this.userService.cards = [];
              
              this.userService.isAuthenticated = true;
              this.userService.role = data.role;
              this.userService.avatar_url = data.avatar_url;
              this.storage.set('shipping_address', JSON.stringify(data.sow_vendor_address));
              this.storage.set('myLat', data.sow_vendor_geocoding.split(",")[0]);
              this.storage.set('myLng', data.sow_vendor_geocoding.split(",")[1]);
              this.storage.set('oddwolves-user-info', JSON.stringify(data));
              console.log(this.userService);
              loadingModal.dismiss();
              this.modalCtrl.dismiss();
            } else if (data && data.result && data.result == false) {
              //console.log("error 1");
              loadingModal.dismiss();
            var alert = await this.alertCtrl.create({
              header: value['Notice'],
              message: value['Sign_In_Failed'],
              buttons: [value['OK']]
            });
            await alert.present();
            }
            else {
              console.log("error 2");
              loadingModal.dismiss();
              var alert = await this.alertCtrl.create({
                header: value['Notice'],
                message: value['Sign_In_Failed'],
                buttons: [value['OK']]
              });
              await alert.present();
            }
          }, (reson) => {
                console.log("error 3");
            loadingModal.dismiss();
            //this.errorModal.present();
          });
        }
        else {
          if (this.email == null || this.email.trim().length == 0) {
            var alert = await this.alertCtrl.create({
              header: value['Notice'],
              message: value['Sign_In_No_Email'],
              buttons: [value['OK']]
            });
            await alert.present();
            return;
          }
          loadingModal.present();
          this.wooService.getCustomerByEmail({ email: this.email }).then(async (data: Array<any>) => {
            if (data.length > 0 && data[0] && data[0].id > 0) {
            console.log(data);
              this.userService.id = data[0].id;
              this.userService.email = data[0].email;
              this.userService.first_name = data[0].first_name;
              this.userService.last_name = data[0].last_name;
              this.userService.name = data[0].first_name + data[0].last_name;
              this.userService.username = data[0].username;
              this.userService.isAuthenticated = true;
              this.userService.postalcode = data[0].postcode;
              this.userService.address_1 = data[0].address_1;
              this.userService.cards = [];
              this.userService.city = data[0].city;
              this.userService.sow_vendor_geocoding = data[0].sow_vendor_geocoding;
              this.userService.sow_vendor_address = data[0].sow_vendor_address;
              this.userService.role = data[0].role;
              this.userService.avatar_url = data[0].avatar_url;
              this.storage.set('oddwolves-user-info', JSON.stringify(data[0]));
              loadingModal.dismiss();
              this.modalCtrl.dismiss();
            } else {
              loadingModal.dismiss();
              var alert = await this.alertCtrl.create({
                header: value['Notice'],
                message: value['Sign_In_Email_Error'],
                buttons: [value['OK']]
              });
              await alert.present();
            }
          }, (reson) => {
          console.log(reson);
            loadingModal.dismiss();
            //this.errorModal.present();
          });
        }
      });
  
    }

  doClose() {
    this.modalCtrl.dismiss();
  }
}
