import { Injectable } from '@angular/core';
//import { Http, Headers } from '@angular/http';
import { AppConfig } from '../app-config';
import { map, catchError, timeout } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { HTTP } from '@ionic-native/http/ngx';
/*
  Generated class for the WoocommerceService provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.

  https://enappd.com/blog/how-to-make-api-calls-in-ionic-capacitor-apps/179/
*/
@Injectable({
  providedIn: 'root',
})
export class WoocommerceService {
  cachedData: any;
  constructor(public appConfig: AppConfig, /*public http: Http,*/ public nativeHttp: HTTP) {

  }

  getStoreInfo() {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wc-api/v1/";
    url = this.initUrl(url, '');
    return new Promise(function (resolve, reject) {
    fetch(url)
      .then(data => {
        if (data) {
          service.cachedData = data.json();
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
    });
  }

  /*getStoreInfo() {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/home_slider/";
    return new Promise(function (resolve, reject) {
      service.nativeHttp.get(service.appConfig.Shop_URL + '/wc-api/v1/','',null).then((data)=>{
        if (data) {
          service.cachedData = JSON.parse(data.data);
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      });
    });
  
  }*/

  /*getProducts(params): Promise<any> {
    var service = this;
    var url = service.appConfig.Shop_URL + "/wp-json/wc/v1/products";
    url = this.initUrl(url, params);

    return new Promise(function (resolve, reject) {
      service.nativeHttp.get(service.initRequest(url, 'get'),null,null).then((data) => {
        if (data) {
         
          service.cachedData = JSON.parse(data.data);
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      }).catch((error)=>{
        alert(JSON.stringify(error));
      });
    });
  }

  getSingleProduct(id) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/wc/v1/products/" + id;
    return new Promise(function (resolve, reject) {
      service.nativeHttp.get(service.initRequest(url, 'get'),null,null).then((data) => {
        if (data) {
         
          service.cachedData = JSON.parse(data.data);
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      }).catch((error)=>{
        alert(JSON.stringify(error));
      });
    });
  }

  getStoreCategories(params) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/wc/v1/products/categories";
    url = this.initUrl(url, params);
    return new Promise(function (resolve, reject) {
      service.nativeHttp.get(service.initRequest(url, 'get'),null,null).then((data) => {
        if (data) {
         
          service.cachedData = JSON.parse(data.data);
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      }).catch((error)=>{
        alert(JSON.stringify(error));
      });
    });
  }

  createOrder(params, data) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wc-api/v3/orders";
    url = this.initUrl(url, params);
    service.nativeHttp.setHeader('*', 'Content-Type', 'application/json');
    service.nativeHttp.setDataSerializer('json');

    return new Promise(function (resolve, reject) {

      service.nativeHttp.post(service.initRequest(url, 'post'), data, { 'Content-Type': 'application/json' })
        .then((data) => {
          if (data) {
            service.cachedData = JSON.parse(data.data);
            resolve(service.cachedData);
          }
          else {
            reject();
          }
        }).catch((error) => {
          reject();
        });
    });

  }


  createCustomer(params, data) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wc-api/v3/customers";
    url = this.initUrl(url, params);
    service.nativeHttp.setHeader('*', 'Content-Type', 'application/json');
    service.nativeHttp.setDataSerializer('json');
    return new Promise(function (resolve, reject) {

    service.nativeHttp.post(service.initRequest(url, 'post'), data, { 'Content-Type': 'application/json' })
        .then((data) => {
          if (data) {
            service.cachedData = JSON.parse(data.data);
            resolve(service.cachedData);
          }
          else {
            reject();
          }
        }).catch((error) => {
          reject();
        });
    });
  }*/

  getCustomerByEmail(params) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/wc/v2/customers/";
    url = this.initUrl(url, params);
    return new Promise(function (resolve, reject) {
    fetch(service.initRequest(url, 'get'))
      .then(data => {
        if (data) {
          service.cachedData = data.json();
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
    });
  }

  /*getCustomerByEmail(params) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/wc/v2/customers/";
    url = this.initUrl(url, params);
    return new Promise(function (resolve, reject) {
      service.nativeHttp.get(service.initRequest(url, 'get'),null,null).then((data) => {
        if (data) {
         
          service.cachedData = JSON.parse(data.data);
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      }).catch((error)=>{
        alert(JSON.stringify(error));
      });
    });
  }*/

  getCustomerData(id, params) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/wc/v2/customers/"+id;
    url = this.initUrl(url, params);
    return new Promise(function (resolve, reject) {
    fetch(service.initRequest(url, 'get'))
      .then(data => {
        if (data) {
          service.cachedData = data.json();
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
    });
  }

  /*getCustomerData(id, params) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/wc/v2/customers/"+ id;
    url = this.initUrl(url, params);
    return new Promise(function (resolve, reject) {
      service.nativeHttp.get(service.initRequest(url, 'get'),null,null).then((data) => {
        if (data) {
         
          service.cachedData = JSON.parse(data.data);
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      }).catch((error)=>{
        alert(JSON.stringify(error));
      });
    });
  }*/

  deleteCustomer(id, params) {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    }
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/wc/v2/customers/"+id;
    url = this.initUrl(url, params);
    return new Promise(function (resolve, reject) {
    fetch(service.initRequest(url, 'delete'))
      .then(data => {
        if (data) {
          service.cachedData = data.json();
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
    });
  }

 /* deleteCustomer(id, params) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/wc/v2/customers/"+ id;
    url = this.initUrl(url, params);
    return new Promise(function (resolve, reject) {
      service.nativeHttp.delete(service.initRequest(url, 'delete'),null,null).then((data) => {
        if (data) {
         
          service.cachedData = JSON.parse(data.data);
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      }).catch((error)=>{
        alert(JSON.stringify(error));
      });
    });
  }*/


  getOrderList(params) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/wc/v1/orders/";
    url = this.initUrl(url, params);
    return new Promise(function (resolve, reject) {
    fetch(service.initRequest(url, 'get'))
      .then(data => {
        if (data) {
          service.cachedData = data.json();
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
    });
  }

 /* getOrderList(params) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/wc/v1/orders/";
    url = this.initUrl(url, params);
    return new Promise(function (resolve, reject) {
      service.nativeHttp.get(service.initRequest(url, 'get'),null,null).then((data) => {
        if (data) {
         
          service.cachedData = JSON.parse(data.data);
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      }).catch((error)=>{
        alert(JSON.stringify(error));
      });
    });
  }*/

  getOrder(id) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/wc/v1/orders/" + id;
    url = this.initUrl(url, '');
    return new Promise(function (resolve, reject) {
    fetch(service.initRequest(url, 'get'))
      .then(data => {
        if (data) {
          service.cachedData = data.json();
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
    });
  }

  /*getOrder(id) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/wc/v1/orders/" + id;
    url = this.initUrl(url, '');
    return new Promise(function (resolve, reject) {
      service.nativeHttp.get(service.initRequest(url, 'get'),null,null).then((data) => {
        if (data) {
         
          service.cachedData = JSON.parse(data.data);
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      }).catch((error)=>{
        alert(JSON.stringify(error));
      });
    });
  }*/


  updateOrderStatus(id, status) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(status) 
    }
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/orders/"+id;
    url = this.initUrl(url, '');
    return new Promise(function (resolve, reject) {
    fetch(service.initRequest(url, 'post'), options)
      .then(data => {
        if (data) {
          service.cachedData = data.json();
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
    });
  }

  /*updateOrderStatus(id, status) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/wc/v1/orders/" + id;
    url = this.initUrl(url, '');
    return new Promise(function (resolve, reject) {

      service.nativeHttp.post(service.initRequest(url, 'post'), { 'status': status }, { 'Content-Type': 'application/json' })
        .then((data) => {
          if (data) {
            service.cachedData = JSON.parse(data.data);
            resolve(service.cachedData);
          }
          else {
            reject();
          }
        }).catch((error) => {
          reject();
        });
    });
  }*/

  /*updateProduct(params,id, data) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/wc/v3/products/" + id;
    url = this.initUrl(url, params);
    return new Promise(function (resolve, reject) {

      service.nativeHttp.post(service.initRequest(url, 'post'), data, { 'Content-Type': 'application/json' })
        .then((data) => {
          if (data) {
            service.cachedData = JSON.parse(data.data);
            resolve(service.cachedData);
          }
          else {
            reject();
          }
        }).catch((error) => {
          reject();
        });
    });
  }*/

  /*deleteProduct(params,id) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/wc/v3/products/" + id;
    url = this.initUrl(url, params);
    return new Promise(function (resolve, reject) {

      service.nativeHttp.delete(service.initRequest(url, 'delete'), {}, { 'Content-Type': 'application/json' })
        .then((data) => {
          if (data) {
            service.cachedData = JSON.parse(data.data);
            resolve(service.cachedData);
          }
          else {
            reject();
          }
        }).catch((error) => {
          reject();
        });
    });
  }*/

  initUrl(url, params) {
    if (params) {
      var keys = Object.keys(params);

      for (var i = 0; i < keys.length; i++) {
        if (i == 0) {
          url += "?" + keys[i] + '=' + params[keys[i]];
        } else {
          url += '&' + keys[i] + '=' + params[keys[i]];
        }
      }
    }
    return url;
  }

  initRequest(url: string, method) {
    if (this.isSSL(url)) {
      if (url.indexOf('?') >= 0) {
        url += '&consumer_key=' + this.appConfig.Shop_ConsumerKey + '&consumer_secret=' + this.appConfig.Shop_ConsumerSecret;
      }
      else {
        url += '?consumer_key=' + this.appConfig.Shop_ConsumerKey + '&consumer_secret=' + this.appConfig.Shop_ConsumerSecret;
      }
      return url;
    }
    else {
      let initParams: any = {};
      let retParams: any = {};
      initParams.url = url;
      initParams.method = method;
      initParams.data = {
        oauth_consumer_key: this.appConfig.Shop_ConsumerKey,
        oauth_nonce: this.getNonce(),
        oauth_signature_method: this.appConfig.Shop_Signature_Method,
        oauth_timestamp: this.getTimeStamp(),
      };

      retParams.oauth_consumer_key = initParams.data.oauth_consumer_key;
      retParams.oauth_nonce = initParams.data.oauth_nonce;
      retParams.oauth_signature_method = initParams.data.oauth_signature_method;
      retParams.oauth_timestamp = initParams.data.oauth_timestamp;
      retParams.oauth_signature = this.authorize(initParams);
      if (url.indexOf('?') >= 0) {
        url += '&';
      }
      else {
        url += '?';
      }
      return url += 'oauth_consumer_key=' + initParams.data.oauth_consumer_key +
        '&oauth_nonce=' + initParams.data.oauth_nonce +
        '&oauth_signature_method=' + initParams.data.oauth_signature_method +
        '&oauth_timestamp=' + initParams.data.oauth_timestamp +
        '&oauth_signature=' + encodeURIComponent(this.authorize(initParams));
    }
  }

  isSSL(str) {
    var tarea = str;
    var tarea_regex = /^(https)/;
    if (tarea_regex.test(String(tarea).toLowerCase()) == true) {
      return true;
    }
    return false;
  }

  getNonce() {
    var word_characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var result = '';

    for (var i = 0; i < this.appConfig.Shop_Nonce_Length; i++) {
      result += word_characters[parseInt(String(Math.random() * word_characters.length), 10)];
    }

    return result;
  }

  getTimeStamp() {
    return parseInt(String(new Date().getTime() / 1000), 10);
  }

  randomJsonpName() {
    var str;
    str = new Date().getTime() + Math.round(Math.random() * 1000000);
    return str;
  };

  authorize(request) {
    if (!request.data) {
      request.data = {};
    }
    let oauth_signature = this.getSignature(request);
    return oauth_signature;
  }

  hash(base_string, key) {
    switch (this.appConfig.Shop_Signature_Method) {
      case 'HMAC-SHA1':
        return CryptoJS.HmacSHA1(base_string, key).toString(CryptoJS.enc.Base64);
      case 'HMAC-SHA256':
        return CryptoJS.HmacSHA256(base_string, key).toString(CryptoJS.enc.Base64);
    }
  }

  getSignature(request) {
    return this.hash(this.getBaseString(request), this.percentEncode(this.appConfig.Shop_ConsumerSecret) + '&');
  }

  getBaseString(request) {
    return request.method.toUpperCase() + '&' + this.percentEncode(this.getBaseUrl(request.url)) + '&' + this.percentEncode(this.getParameterString(request));
  }

  getParameterString(request) {
    var base_string_data = this.sortObject(this.percentEncodeData(this.mergeObject(request.data, this.deParamUrl(request.url))));
    var data_str = '';

    for (var key in base_string_data) {
      var value = base_string_data[key];
      if (value && Array.isArray(value)) {
        value.sort();
        var valString = "";
        value.forEach((function (item, i) {
          valString += key + '=' + item;
          if (i < value.length) {
            valString += "&";
          }
        }).bind(this));
        data_str += valString;
      } else {
        data_str += key + '=' + value + '&';
      }
    }

    data_str = data_str.substr(0, data_str.length - 1);

    return data_str;
  }

  mergeObject(obj1, obj2) {
    var merged_obj = obj1;
    for (var key in obj2) {
      merged_obj[key] = obj2[key];
    }
    return merged_obj;
  }

  deParam(param) {
    var arr = param.split('&');
    var data = {};

    for (var i = 0; i < arr.length; i++) {
      var item = arr[i].split('=');
      data[item[0]] = decodeURIComponent(item[1]);
    }
    return data;
  }

  deParamUrl(url) {
    var tmp = url.split('?');

    if (tmp.length === 1)
      return {};

    return this.deParam(tmp[1]);
  }

  percentEncodeData(data) {
    var result = {};

    for (var key in data) {
      var value = data[key];
      if (value && Array.isArray(value)) {
        var newValue = [];
        value.forEach((function (val) {
          newValue.push(this.percentEncode(val));
        }).bind(this));
        value = newValue;
      } else {
        value = this.percentEncode(value);
      }
      result[this.percentEncode(key)] = value;
    }

    return result;
  }

  getBaseUrl(url) {
    return url.split('?')[0];
  }

  percentEncode(str) {
    return encodeURIComponent(str)
      .replace(/\!/g, "%21")
      .replace(/\*/g, "%2A")
      .replace(/\'/g, "%27")
      .replace(/\(/g, "%28")
      .replace(/\)/g, "%29")
      ;
  }

  sortObject(data) {
    var keys = Object.keys(data);
    var result = {};

    keys.sort();

    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      result[key] = data[key];
    }

    return result;
  }

}

export class CartProduct {
  public product_id: number;
  public name: string;
  public price: number;
  public quantity: number;
  public variation_id: number;
  public variations: any;
  public variation_name: string;
  public thumb: string;
  public vendor_id:number;
  public prodsubtotal:number;

  constructor() {
    this.name = '';
    this.price = 0;
    this.product_id = 0;
    this.quantity = 0;
    this.variation_id = 0;
    this.variation_name = '';
    this.thumb = '';
    this.vendor_id=0;
    this.prodsubtotal=0;
  }
}

export class WishlistProduct {
  public product_id: number;
  public price: number;
  public product_image: string;
  public vendor_id: number;
  public shop_name: string;
  public shop_address: string;
}

export class Cart {
  public CartProductArray: Array<CartProduct>;
  public total: number;
  public totalShip: number;
}

//this was not here before
export class Attribute {
  public name: string;
  public slug: string;
  public type: string;
  public order_by: string;
  public has_archives: boolean;

  constructor(){
    this.name='';
    this.slug='';
    this.type='select';
    this.order_by='menu_order';
    this.has_archives=true;
  }
}