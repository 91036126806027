import { Injectable } from '@angular/core';
/*
  Generated class for the ShopConstant provider.
  
  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/  
@Injectable({
  providedIn: 'root',
})

export class AppConfig { 
  Shop_Signature_Method = 'HMAC-SHA1';
  Shop_Nonce_Length = 32;
  Shop_Parameter_Seperator = ', ';
  Shop_Language = 'de';   
 
  Shop_Name = "Mangiamoo";
  Shop_Version = "2.0";  

  Shop_URL = "https://mangiamoo-services.ch";
  Shop_ConsumerKey = "ck_0371e8d95002dc1fdbcd6077585ac45cd34b2530";
  Shop_ConsumerSecret = "cs_3aae9c3645ff6a5d43c827aeae10814d82ac9a24";
  App_Secret = "ad3b5fe554c417d97efd631f0d4c9fae83268efa12cc6b5f46bd273c513d9d4a";
 
  Is_Vendor_Pro = true;
 
  OneSignal_UserId = '';
  OneSignal_PushToken = '';

  ApplePayMerchantID = "merchant.mangiamoo";

  Show_Home_Slide = true;//whether show home slide

  Shop_Currency = "CHF ";//no need setting,will get from your Website
  Shop_Currency_format = "CHF ";//no need setting,will get from your Website

  Shop_Shipping = [
  //{ id: 'flat_rate:1', name: 'Flat Rate', cost: 5.00 },
  //{ id: 'flat_rate:2', name: 'Worldwide Flat Rate', cost: 15.00 }
  ]; 

  //Stripe payment setting
  Enable_Stripe = true;//Enable or disable stripe
  Stripe_Live_Secret_Key = "sk_live_SGUyFfCT3FF9QVsi2VbR1anz";//Live mode Secret Key
  Stripe_Live_Publishable_Key = "pk_live_RFhGMPBgUogUGMR9jyoga8Sj";//Live mode Publishable Key
 
  Enable_Stripe_Test_Mode = false;//Enable or disable test mode
  Stripe_Test_Secret_Key = "sk_test_ZDVbxprwkP9d3deLz5icWmFd";//Test mode Secret Key
  Stripe_Test_Publishable_Key = "pk_test_eqcpsZ4QofdTvIuUT3CMns9T";//Test mode Publishable Key

  //Paypal setting
  //PayPalEnvironmentNoNetwork, PayPalEnvironmentSandbox, PayPalEnvironmentProduction
  //if you want to test payment,set Environments to PayPalEnvironmentSandbox,otherwise set to PayPalEnvironmentProduction
  Paypal_Environments = "PayPalEnvironmentSandbox";
  PayPal_EnvironmentProduction = "AQMJWzy87XEgkDHSjTkGmIEo0C7IKE_a_MBlHnAq_dUxPjT-mRcTUH6K4yBZy1Vr9WYdYrMeCgyrPhCU";
  PayPal_EnvironmentSandbox = "Abmc1nUZqHzbeDjyjuz0fYGo1FwVujydrXBLUxu01KItgiamDwre_pJapzK0y8DHl0ubFTjmeIGU4hQE";
 
  //Onesignal setting
  //Please check our online document set these
  Onesignal_Enable = true;//enable/disable Onesignal
  OneSignal_AppID = "e9d6ced0-4184-42bd-969e-ca9d6e2478a5";
  GCM_SenderID = "631766550027";

  //enable login with password,need to add codes to woocommerce
  Eanble_Login_With_Password = true;

  //Contact page info 
  Service_In_Weekdays = "Monday-Friday (9am - 4pm)";
  Service_Weekend = "Saturday-Sunday (12pm - 4pm)";
  Service_Tel = "info@mangiamoo.ch";

  //Faq page info
  //Title:question Title
  //content:the answser
  Question_Array = [
    {
      'Title': '#1',
      Content: ``
    },
    {
      'Title': '#2',
      Content: ``
    },
    {
      'Title': '#3',
      Content: `` },
    {
      'Title': '#4',
      Content: ``},
    {
      'Title': '#5',
      Content: `` },
  ];



  //About page info
  Introduction = `Movet apeirian verterem eu quo, vix elit voluptatum te, has ea solum viris audiam. Mel ex suas fugit altera. Amet soluta
    quo id, hinc adhuc alterum nam ad. Qui in natum laudem fabulas.`;
  Address1 = "VARANO Networks";
  Address2 = "Wilenstrasse 42, 9532 Rickenbach";
  CopyRight = "@ mangiamoo.ch";

  //logo in app not app icon
  Logo_Image = "assets/img/logolargenoback.png";//copy your own image to assets/img/ and set logolargenoback.png to yourself. 

  constructor() {
  }
}
