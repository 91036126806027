
/*
  Generated class for the TbarService provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class TbarService {
hideBar: boolean = false;
cartBage:number=0;
  constructor() {
   
  }
}