import { Injectable } from '@angular/core';
//import { Http, Headers } from '@angular/http';
import { AppConfig } from '../app-config';
import { map, catchError, timeout } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
//import { Observable } from 'rxjs';
//import { jsonpFactory } from '@angular/http/src/http_module';
import { HTTP } from '@ionic-native/http/ngx';

/*
  Generated class for the SowService provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/
@Injectable({
  providedIn: 'root',
})
export class SowService {
  cachedData: any;
  constructor(/*public http: Http,*/ public appConfig: AppConfig, public nativeHttp: HTTP) {

  }

  getAppData() {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/appData/";
    url = this.initUrl(url, '');
    return new Promise(function (resolve, reject) {
    fetch(url)
      .then(data => {
        if (data) {
          service.cachedData = data.json();
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
    });
  }

  /*getAppData() {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/appData/";
    url = this.initUrl(url, '');
    var headers = new Headers();
    headers.append('Content-Type', 'text/plain');
   
    return new Promise(function (resolve, reject) {
     
      service.nativeHttp.get(url,null,null).then((data) => {
        if (data) {
         
          service.cachedData = JSON.parse(data.data);
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      }).catch((error)=>{
        alert(JSON.stringify(error));
      });
    });
  }*/

  login(params) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/user_login/";
    url = this.initUrl(url, params);
    return new Promise(function (resolve, reject) {
    fetch(url)
      .then(data => {
        if (data) {
          service.cachedData = data.json();
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
    });
  }



  /*login(params) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/user_login/";
    url = this.initUrl(url, params);
    var headers = new Headers();
    headers.append('Content-Type', 'text/plain');
    return new Promise(function (resolve, reject) {
      service.nativeHttp.get(url,null,null).then((data) => {
        if (data) {
         
          service.cachedData = JSON.parse(data.data);
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      }).catch((error)=>{
        alert(JSON.stringify(error));
      });
     
    });
  }
*/ 

markShipped(params) {
  var service = this;
  let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/mark_shipped/";
  url = this.initUrl(url, params);
  return new Promise(function (resolve, reject) {
  fetch(url)
    .then(data => {
      if (data) {
        service.cachedData = data.json();
        resolve(service.cachedData);
      }
      else {
        reject();
      }
    })
    .catch(e => {
      alert(JSON.stringify(e));
    });
  });
}

/*markShipped(params) {
  var service = this;
  let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/mark_shipped/";
  url = this.initUrl(url, params);
  var headers = new Headers();
  headers.append('Content-Type', 'text/plain');
 
  return new Promise(function (resolve, reject) {
   
    service.nativeHttp.get(url,null, null).then((data) => {
      if (data) {
       
        service.cachedData = JSON.parse(data.data);
        resolve(service.cachedData);
      }
      else {
        reject();
      }
    }).catch((error)=>{
      alert(JSON.stringify(error));
    });
  });
}*/

changePassword(params) {
  var service = this;
  let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/change_password/";
  url = this.initUrl(url, params);
  return new Promise(function (resolve, reject) {
  fetch(url)
    .then(data => {
      if (data) {
        service.cachedData = data.json();
        resolve(service.cachedData);
      }
      else {
        reject();
      }
    })
    .catch(e => {
      alert(JSON.stringify(e));
    });
  });
}

  /*changePassword(params) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/change_password/";
    url = this.initUrl(url, params);
    var headers = new Headers();

    headers.append('Content-Type', 'text/plain');

    return new Promise(function (resolve, reject) {
      service.nativeHttp.get(url,null,null).then((data) => {
        if (data) {
         
          service.cachedData = JSON.parse(data.data);
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      }).catch((error)=>{
        alert(JSON.stringify(error));
      });
     
    });
  }*/

  resetPassword(params) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/password_reset/";
    url = this.initUrl(url, params);
    return new Promise(function (resolve, reject) {
    fetch(url)
      .then(data => {
        if (data) {
          service.cachedData = data.json();
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
    });
  }

  /*resetPassword(params) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/password_reset/";
    url = this.initUrl(url, params);
    var headers = new Headers();

    headers.append('Content-Type', 'text/plain');
    
    return new Promise(function (resolve, reject) {
      service.nativeHttp.get(url,null,null).then((data) => {
        if (data) {
         
          service.cachedData = JSON.parse(data.data);
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      }).catch((error)=>{
        alert(JSON.stringify(error));
      });
     
    });
  }*/

  updateUser(params, data) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data) 
    }
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/update_user/";
    url = this.initUrl(url, params);
    return new Promise(function (resolve, reject) {
    fetch(service.initRequest(url, 'post'), options)
      .then(data => {
        if (data) {
          service.cachedData = data.json();
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
    });
  }


  /*updateUser(params, data) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/update_user/";
    url = this.initUrl(url, params);
    service.nativeHttp.setHeader('*', 'Content-Type', 'application/json');
    service.nativeHttp.setDataSerializer('json');

    return new Promise(function (resolve, reject) {

      service.nativeHttp.post(service.initRequest(url, 'post'), data, { 'Content-Type': 'application/json' })
        .then((data) => {
          if (data) {
            service.cachedData = JSON.parse(data.data);
            resolve(service.cachedData);
          }
          else {
            reject();
          }
        }).catch((error) => {
          reject();
        });
    });
  }*/

  getVendorDetails(vid, params) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/get_vendor_details/" + vid;
    url = this.initUrl(url, params);
    return new Promise(function (resolve, reject) {
    fetch(url)
      .then(data => {
        if (data) {
          service.cachedData = data.json();
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
    });
  }

  /*getVendorDetails(vid, params) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/get_vendor_details/" + vid;
    url = this.initUrl(url, params);
    var headers = new Headers();
    headers.append('Content-Type', 'text/plain');
   
    return new Promise(function (resolve, reject) {
     
      service.nativeHttp.get(url,null,null).then((data) => {
        if (data) {
         
          service.cachedData = JSON.parse(data.data);
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      }).catch((error)=>{
        alert(JSON.stringify(error));
      });
    });
  }*/

  getAddress(params, data) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data) 
    }
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/get_address/";
    url = this.initUrl(url, params);
    return new Promise(function (resolve, reject) {
    fetch(service.initRequest(url, 'post'), options)
      .then(data => {
        if (data) {
          service.cachedData = data.json();
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
    });
  }


  /*getAddress(params, data) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/get_address/";
    url = this.initUrl(url, params);
    service.nativeHttp.setHeader('*', 'Content-Type', 'application/json');
    service.nativeHttp.setDataSerializer('json');

    return new Promise(function (resolve, reject) {

      service.nativeHttp.post(service.initRequest(url, 'post'), data, { 'Content-Type': 'application/json' })
        .then((data) => {
          if (data) {
            service.cachedData = JSON.parse(data.data);
            resolve(service.cachedData);
          }
          else {
            reject();
          }
        }).catch((error) => {
          reject();
        });
    });
  }*/

  createReservation(params, data) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data) 
    }
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/create_reservation/";
    url = this.initUrl(url, params);
    return new Promise(function (resolve, reject) {
    fetch(service.initRequest(url, 'post'), options)
      .then(data => {
        if (data) {
          service.cachedData = data.json();
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
    });
  }

  /*createReservation(params, data) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/create_reservation/";
    url = this.initUrl(url, params);
    service.nativeHttp.setHeader('*', 'Content-Type', 'application/json');
    service.nativeHttp.setDataSerializer('json');

    return new Promise(function (resolve, reject) {

      service.nativeHttp.post(service.initRequest(url, 'post'), data, { 'Content-Type': 'application/json' })
        .then((data) => {
          if (data) {
            service.cachedData = JSON.parse(data.data);
            resolve(service.cachedData);
          }
          else {
            reject();
          }
        }).catch((error) => {
          reject();
        });
    });
  }*/

  getReservationList(params) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/reservations/";
    url = this.initUrl(url, params);
    return new Promise(function (resolve, reject) {
    fetch(url)
      .then(data => {
        if (data) {
          service.cachedData = data.json();
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
    });
  }

  /*getReservationList(params) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/wp/v2/reservations/";
    url = this.initUrl(url, params);
    return new Promise(function (resolve, reject) {
      service.nativeHttp.get(service.initRequest(url, 'get'),null,null).then((data) => {
        if (data) {
         
          service.cachedData = JSON.parse(data.data);
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      }).catch((error)=>{
        alert(JSON.stringify(error));
      });
    });
  }*/

  getVendorReservationList(params) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/vendor_reservation/";
    url = this.initUrl(url, params);
    return new Promise(function (resolve, reject) {
    fetch(url)
      .then(data => {
        if (data) {
          service.cachedData = data.json();
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
    });
  }

  /*getVendorReservationList(params) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/vendor_reservation/";
    url = this.initUrl(url, params);
    var headers = new Headers();
    headers.append('Content-Type', 'text/plain');
   
    return new Promise(function (resolve, reject) {
     
      service.nativeHttp.get(url,null,null).then((data) => {
        if (data) {
         
          service.cachedData = JSON.parse(data.data);
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      }).catch((error)=>{
        alert(JSON.stringify(error));
      });
    });
  }*/


  send_request(params, data) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data) 
    }
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/sendMail/";
    url = this.initUrl(url, params);
    return new Promise(function (resolve, reject) {
    fetch(service.initRequest(url, 'post'), options)
      .then(data => {
        if (data) {
          service.cachedData = data.json();
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
    });
  }

  /*send_request(params, data) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/sow/v1/sendMail/";
    url = this.initUrl(url, params);
    service.nativeHttp.setHeader('*', 'Content-Type', 'application/json');
    service.nativeHttp.setDataSerializer('json');

    return new Promise(function (resolve, reject) {

      service.nativeHttp.post(service.initRequest(url, 'post'), data, { 'Content-Type': 'application/json' })
        .then((data) => {
          if (data) {
            service.cachedData = JSON.parse(data.data);
            resolve(service.cachedData);
          }
          else {
            reject();
          }
        }).catch((error) => {
          reject();
        });
    });

  }*/

  initUrl(url, params) {
    if (params) {
      var keys = Object.keys(params);

      for (var i = 0; i < keys.length; i++) {
        if (i == 0) {
          url += "?" + keys[i] + '=' + params[keys[i]];
        } else {
          url += '&' + keys[i] + '=' + params[keys[i]];
        }
      }
    }
    return url;
  }

  initRequest(url: string, method) {
    if (this.isSSL(url)) {
      if (url.indexOf('?') >= 0) {
        url += '&consumer_key=' + this.appConfig.Shop_ConsumerKey + '&consumer_secret=' + this.appConfig.Shop_ConsumerSecret;
      }
      else {
        url += '?consumer_key=' + this.appConfig.Shop_ConsumerKey + '&consumer_secret=' + this.appConfig.Shop_ConsumerSecret;
      }
      return url;
    }
    else {
      let initParams: any = {};
      let retParams: any = {};
      initParams.url = url;
      initParams.method = method;
      initParams.data = {
        oauth_consumer_key: this.appConfig.Shop_ConsumerKey,
        oauth_nonce: this.getNonce(),
        oauth_signature_method: this.appConfig.Shop_Signature_Method,
        oauth_timestamp: this.getTimeStamp(),
      };

      retParams.oauth_consumer_key = initParams.data.oauth_consumer_key;
      retParams.oauth_nonce = initParams.data.oauth_nonce;
      retParams.oauth_signature_method = initParams.data.oauth_signature_method;
      retParams.oauth_timestamp = initParams.data.oauth_timestamp;
      retParams.oauth_signature = this.authorize(initParams);
      if (url.indexOf('?') >= 0) {
        url += '&';
      }
      else {
        url += '?';
      }
      return url += 'oauth_consumer_key=' + initParams.data.oauth_consumer_key +
        '&oauth_nonce=' + initParams.data.oauth_nonce +
        '&oauth_signature_method=' + initParams.data.oauth_signature_method +
        '&oauth_timestamp=' + initParams.data.oauth_timestamp +
        '&oauth_signature=' + encodeURIComponent(this.authorize(initParams));
    }
  }

  isSSL(str) {
    var tarea = str;
    var tarea_regex = /^(https)/;
    if (tarea_regex.test(String(tarea).toLowerCase()) == true) {
      return true;
    }
    return false;
  }

  getNonce() {
    var word_characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var result = '';

    for (var i = 0; i < this.appConfig.Shop_Nonce_Length; i++) {
      result += word_characters[parseInt(String(Math.random() * word_characters.length), 10)];
    }

    return result;
  }

  getTimeStamp() {
    return parseInt(String(new Date().getTime() / 1000), 10);
  }

  randomJsonpName() {
    var str;
    str = new Date().getTime() + Math.round(Math.random() * 1000000);
    return str;
  };

  authorize(request) {
    if (!request.data) {
      request.data = {};
    }
    let oauth_signature = this.getSignature(request);
    return oauth_signature;
  }

  hash(base_string, key) {
    switch (this.appConfig.Shop_Signature_Method) {
      case 'HMAC-SHA1':
        return CryptoJS.HmacSHA1(base_string, key).toString(CryptoJS.enc.Base64);
      case 'HMAC-SHA256':
        return CryptoJS.HmacSHA256(base_string, key).toString(CryptoJS.enc.Base64);
    }
  }

  getSignature(request) {
    return this.hash(this.getBaseString(request), this.percentEncode(this.appConfig.Shop_ConsumerSecret) + '&');
  }

  getBaseString(request) {
    return request.method.toUpperCase() + '&' + this.percentEncode(this.getBaseUrl(request.url)) + '&' + this.percentEncode(this.getParameterString(request));
  }

  getParameterString(request) {
    var base_string_data = this.sortObject(this.percentEncodeData(this.mergeObject(request.data, this.deParamUrl(request.url))));
    var data_str = '';

    for (var key in base_string_data) {
      var value = base_string_data[key];
      if (value && Array.isArray(value)) {
        value.sort();
        var valString = "";
        value.forEach((function (item, i) {
          valString += key + '=' + item;
          if (i < value.length) {
            valString += "&";
          }
        }).bind(this));
        data_str += valString;
      } else {
        data_str += key + '=' + value + '&';
      }
    }

    data_str = data_str.substr(0, data_str.length - 1);

    return data_str;
  }

  mergeObject(obj1, obj2) {
    var merged_obj = obj1;
    for (var key in obj2) {
      merged_obj[key] = obj2[key];
    }
    return merged_obj;
  }

  deParam(param) {
    var arr = param.split('&');
    var data = {};

    for (var i = 0; i < arr.length; i++) {
      var item = arr[i].split('=');
      data[item[0]] = decodeURIComponent(item[1]);
    }
    return data;
  }

  deParamUrl(url) {
    var tmp = url.split('?');

    if (tmp.length === 1)
      return {};

    return this.deParam(tmp[1]);
  }

  percentEncodeData(data) {
    var result = {};

    for (var key in data) {
      var value = data[key];
      if (value && Array.isArray(value)) {
        var newValue = [];
        value.forEach((function (val) {
          newValue.push(this.percentEncode(val));
        }).bind(this));
        value = newValue;
      } else {
        value = this.percentEncode(value);
      }
      result[this.percentEncode(key)] = value;
    }

    return result;
  }

  getBaseUrl(url) {
    return url.split('?')[0];
  }

  percentEncode(str) {
    return encodeURIComponent(str)
      .replace(/\!/g, "%21")
      .replace(/\*/g, "%2A")
      .replace(/\'/g, "%27")
      .replace(/\(/g, "%28")
      .replace(/\)/g, "%29")
      ;
  }

  sortObject(data) {
    var keys = Object.keys(data);
    var result = {};

    keys.sort();

    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      result[key] = data[key];
    }

    return result;
  }

}
